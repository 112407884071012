import { startOfWeek, differenceInMinutes } from "date-fns";
import { AppointmentType } from "./services/types";

export const weekStart = startOfWeek(new Date());
const minsInDay = 1440;

export const diffInPercent = (start: Date, end: Date) => {
  const diff = differenceInMinutes(start, end);
  return `${Math.min((diff / minsInDay) * 100, 100).toFixed(3)}%`;
};

export function range<T = number>(end: number, map?: (i: number) => T): T[] {
  map ??= (i) => i as T;
  return Array.from({ length: end }, (_, i) => map!(i));
}

export function zeroPad(n: number, length: number = 2) {
  return n.toString().padStart(length, "0");
}

export function fixDates(obj: any, keysToFix: any[]) {
  if (Array.isArray(obj)) {
    obj.forEach((v) => fixDates(v, keysToFix));
  } else {
    keysToFix.forEach((key) => {
      switch (typeof key) {
        case "string": {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (value) {
              if (typeof value === "object") {
                console.log("obj", key);
                obj[key] = value.map((d: string) => new Date(d));
              } else {
                obj[key] = new Date(value);
              }
            }
          }
          break;
        }

        case "object": {
          for (const subKey in key) {
            fixDates(obj[subKey], key[subKey]);
          }
          break;
        }
      }
    });
  }

  return obj;
}

type TypeHandler<T> = ((e: T) => T) | T;
type TypeHandlers<T> = {
  inPerson: TypeHandler<T>;
  video: TypeHandler<T>;
  phone: TypeHandler<T>;
};

const isFunction = (o: any) => typeof o === "function";

export function switchOnAppointmentType<
  T extends { appointmentType: AppointmentType },
  TH
>(booking: T, handlers: TypeHandlers<TH>) {
  function handle(handler: TypeHandler<TH>) {
    return isFunction(handler)
      ? (handler as (b: T) => TH)(booking)
      : (handler as TH);
  }

  if (!booking.appointmentType && process.env.NODE_ENV === "development") {
    throw new Error(`Unknown Appointment Type: ${booking.appointmentType}.`);
  }

  switch (booking.appointmentType) {
    case "Video":
      return handle(handlers.video);
    case "Phone":
      return handle(handlers.phone);
    case "In Person":
      return handle(handlers.inPerson);
    default:
      return handle(handlers.inPerson);
  }
}

export const DATE_TIME_FORMAT = "h:mmaaa, E d MMM yyyy";
