import { CalendarIcon } from "@heroicons/react/24/outline";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { intlFormatDistance } from "date-fns";
import { ClosePopupButton } from "../../components/Popup";
import { Appointment } from "./Appointment";
import { Booking, Calendar, CalendarEvent } from "../../services/types";
import { useBooking } from "../../services/api/booking";
import { KeyedMutator } from "swr";

export const AppointmentPopup = ({
  event,
  onClose,
}: {
  event: CalendarEvent;
  onClose?: KeyedMutator<Calendar>;
}) => {
  const { booking, isLoading } = useBooking(event);

  return (
    <BookingPopup booking={booking} isLoading={isLoading} onClose={onClose} />
  );
};

export const BookingPopup = ({
  booking,
  isLoading,
  onClose,
}: {
  booking: Booking | null;
  isLoading?: boolean;
  onClose?: KeyedMutator<Calendar>;
}) => {
  return (
    <Box sx={{ maxWidth: 500, minWidth: { sm: 500 } }} px={2} pt={2}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h5" sx={{ color: (t) => t.palette.primary.main }}>
          <CalendarIcon className="icon" /> Appointment{" "}
          {booking && intlFormatDistance(booking.start, new Date())}
        </Typography>
        <ClosePopupButton />
      </Stack>
      {isLoading ? (
        <Skeleton width="100%" height={372} />
      ) : (
        booking && <Appointment booking={booking} onClose={onClose} />
      )}
    </Box>
  );
};
