import {
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as WelcomeImage } from "../../assets/images/welcome.svg"
import { ProductName } from "../../theme";
import { Helmet } from "react-helmet";

export const Welcome = () => {
  return (
    <Stack spacing={2} alignItems="center">
      <Helmet>
        <title>Welcome</title>
      </Helmet>
      <WelcomeImage style={{maxWidth: "90%", maxHeight: 200}} />
      <Typography component="h1" variant="h2" textAlign="center">
        Welcome to<br /><Box display="inline-block" sx={{color: "primary.main"}}>{ProductName}</Box>
      </Typography>
      <Typography variant="body1" sx={{maxWidth: 400, textAlign: "center"}}>
        Let's get you set up and running with a simpler, smarter way to keep on top of your appointments and schedule. This will only take a minute.
      </Typography>
      <Button component={Link} to="link-calendar" color="secondary" size="large" variant="contained">Get Started</Button>
    </Stack>
  );
};
