import {
  Paper,
  Divider,
  Typography,
  Box,
  Stack,
  Skeleton,
} from "@mui/material";
import { useNextBooking } from "../../services/api/booking";
import { Appointment } from "../booking/Appointment";
import { ReactComponent as EmptyBooking } from "../../assets/images/booking.svg";
import { ReactComponent as CalendarClock } from "../../assets/images/calendar-clock.svg";
import { ReactComponent as Timer } from "../../assets/images/timer.svg";
import { ProductName } from "../../theme";
import { useEffect, useState } from "react";
import { Booking } from "../../services/types";
import { formatDistanceToNowStrict, isBefore } from "date-fns";

export const NextAppointment = () => {
  const [nextBooking, setNextBooking] = useState<Booking>();
  const { data, isLoading } = useNextBooking();

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const sanitizedNextBooking = {
        ...data,
        start: new Date(data.start),
        end: new Date(data.end),
      };
      setNextBooking(sanitizedNextBooking);
    } else {
      setNextBooking(undefined);
    }
  }, [data]);

  if (!nextBooking) {
    return (
      <Paper sx={{ flex: 1, borderRadius: "8px" }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ padding: "24px" }}
        >
          <Typography sx={{ fontWeight: 700 }}>
            Your next appointment
          </Typography>
        </Stack>
        <Divider />
        {isLoading ? (
          <Skeleton
            height={376}
            sx={{ margin: "16px", transform: "none" }}
            variant="rounded"
            animation="wave"
          />
        ) : (
          <Stack
            sx={{ alignItems: "center", padding: "24px", paddingBottom: "0px" }}
          >
            <Box>
              <EmptyBooking />
            </Box>
            <Typography variant="h5" textAlign="center">
              Your {ProductName} agenda is clear
            </Typography>
            <Typography textAlign="center">
              When a client makes a new booking, the details of your next
              appointment will be shown here.
            </Typography>
          </Stack>
        )}
      </Paper>
    );
  }

  const isAppointmentNow = isBefore(nextBooking.start, new Date());

  return (
    <Paper sx={{ flex: 1, borderRadius: "8px" }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          background: (t) =>
            isAppointmentNow
              ? t.palette.appointment.currentBackground
              : t.palette.appointment.upcomingBackground,
          transition: "background 0.4s",
          borderRadius: "8px",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          padding: "24px",
        }}
      >
        <Typography
          sx={{
            color: (t) =>
              isAppointmentNow
                ? t.palette.appointment.currentText
                : t.palette.appointment.upcomingText,
            transition: "color 0.2s",
            fontWeight: 700,
          }}
        >
          Your next appointment
        </Typography>
        <Stack direction="row">
          {isAppointmentNow ? (
            <>
              <Timer />
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: (t) => t.palette.appointment.currentText,
                  marginLeft: "10px",
                }}
              >
                {`${formatDistanceToNowStrict(nextBooking.end)} left`}
              </Typography>
            </>
          ) : (
            <>
              <CalendarClock />
              <Typography
                sx={{
                  textTransform: "uppercase",
                  color: (t) => t.palette.appointment.upcomingText,
                  marginLeft: "10px",
                }}
              >
                {formatDistanceToNowStrict(nextBooking.start, {
                  addSuffix: true,
                })}
              </Typography>
            </>
          )}
        </Stack>
      </Stack>
      <Divider />
      <Stack sx={{ padding: "24px", paddingBottom: "0px" }}>
        <Appointment booking={nextBooking} />
      </Stack>
    </Paper>
  );
};
