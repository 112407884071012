import Stack from "@mui/material/Stack";
import { NextAppointment } from "./NextAppointment";
import { PastAppointments } from "./PastAppointments";

export const JobsToBeDone = () => {
  return (
    <Stack direction={{ xs: "column", sm: "row" }} spacing={4}>
      <NextAppointment />
      <PastAppointments />
    </Stack>
  );
};
