import { Helmet } from "react-helmet";
import { Calendar } from "./Calendar";
import { JobsToBeDone } from "./JobsToBeDone";
import { Stack } from "@mui/material";
import { PopupContext } from "../../components/Popup";

export const Dashboard = () => {
  return (
    <PopupContext>
      <Stack spacing={4}>
        <Helmet>
          <title>My Dashboard</title>
        </Helmet>
        <JobsToBeDone />
        <Calendar />
      </Stack>
    </PopupContext>
  );
};
