import { Box } from "@mui/material";
import { Calendar, CalendarEvent } from "../../services/types";
import { usePopup } from "../../components/Popup";
import { AppointmentPopup } from "../booking/AppointmentPopup";
import { diffInPercent, switchOnAppointmentType } from "../../utils";
import {
  DevicePhoneMobileIcon,
  UserIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { KeyedMutator } from "swr";

export function Bookings({
  events,
  date,
  mutate,
}: {
  events?: CalendarEvent[];
  date: Date;
  mutate: KeyedMutator<Calendar>;
}) {
  const popover = usePopup();
  const bookings = events?.filter((e) => e.type === "Booking");

  const click = (target: HTMLDivElement, event: CalendarEvent) => {
    if (target) {
      popover?.show(
        target,
        <AppointmentPopup event={event} onClose={mutate} />
      );
    }
  };

  return (
    <>
      {bookings?.map((event, i) => (
        <Box
          key={i}
          sx={{
            top: diffInPercent(event.start, date),
            height: diffInPercent(event.end, event.start),
            px: 0.5,
            width: "100%",
            position: "absolute",
          }}
        >
          <Box
            borderRadius={1}
            onClick={(e) => click(e.currentTarget, event)}
            sx={{
              width: "100%",
              height: "100%",
              background: (t) =>
                switchOnAppointmentType(event, {
                  video: t.palette.calendar.booking.video,
                  phone: t.palette.calendar.booking.phone,
                  inPerson: t.palette.calendar.booking.inPerson,
                }),
              color: (t) => t.palette.calendar.booking.contrastText,
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              px: 1,
              cursor: "pointer",
              "&:hover": {
                boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.15)",
              },
            }}
          >
            {switchOnAppointmentType(event, {
              video: <VideoCameraIcon className="icon" />,
              phone: <DevicePhoneMobileIcon className="icon" />,
              inPerson: <UserIcon className="icon" />,
            })}
          </Box>
        </Box>
      ))}
    </>
  );
}
