import { Box, Stack } from "@mui/material";
import { Calendar, CalendarEvent, Duration } from "../../services/types";
import { Bookings } from "./Bookings";
import { Externals } from "./Externals";
import { TimeBar } from "./TimeBar";
import { BlockedSlots } from "./BlockedSlots";
import { KeyedMutator } from "swr";
import AvailableSlots from "./AvailableSlots";
import { isToday } from "date-fns";

export type CalendarDayProps = {
  day: {
    date: Date;
    availableSlots: Duration[];
    events?: CalendarEvent[];
  };
  mutate: KeyedMutator<Calendar>;
};

export function CalendarDay({ day, mutate }: CalendarDayProps) {
  const events = day.events ?? [];
  events.sort((a, b) => a.start.getTime() - b.start.getTime());

  return (
    <Stack
      alignItems="center"
      m={0}
      sx={{
        borderLeft: "1px solid",
        borderRight: "1px solid",
        borderColor: "divider",
        width: "100%",
      }}
    >
      <Box minHeight={1200} width="100%" position="relative">
        <AvailableSlots
          date={day.date}
          availableSlots={day.availableSlots}
          mutate={mutate}
        />
        <Externals events={day.events} date={day.date} />
        <Bookings events={day.events} date={day.date} mutate={mutate} />
        <BlockedSlots events={day.events} date={day.date} mutate={mutate} />
        {isToday(day.date) && <TimeBar />}
      </Box>
    </Stack>
  );
}
