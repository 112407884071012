import { Checkbox, Divider, FormControlLabel, Stack } from "@mui/material";
import { Control, Controller, UseFormGetValues } from "react-hook-form";
import { AvailabilityProps } from "./AvailablityHours";
import { WorkHours } from "./WorkHours";

export function WorkingDay({
  day,
  dayName,
  control,
  getValues,
}: {
  day: number;
  dayName: string;
  control: Control<AvailabilityProps>;
  getValues: UseFormGetValues<AvailabilityProps>;
}) {
  const isAvailable = getValues(`availability.${day}.isAvailable`);

  return (
    <>
      <Stack
        alignItems="flex-start"
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        my={2}
      >
        <FormControlLabel
          sx={{ flex: 1 }}
          control={
            <Controller
              name={`availability.${day}.isAvailable`}
              render={({ field }) => (
                <Checkbox checked={field.value} onChange={field.onChange} />
              )}
              control={control}
            />
          }
          label={
            <div>
              <b>{dayName}</b>
              <div>{isAvailable ? "Available" : "Unavailable"}</div>
            </div>
          }
        />
        {isAvailable && <WorkHours day={day} control={control} />}
      </Stack>
      <Divider />
    </>
  );
}
