import { CircularProgress, Stack, Typography } from "@mui/material";
import { ReactComponent as Balloons } from "../../assets/images/balloons.svg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

export const Complete = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => navigate("/dashboard"), 5000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <Stack spacing={4} alignItems="center">
      <Helmet>
        <title>Good to go</title>
      </Helmet>
      <Balloons />
      <Typography component="h1" variant="h5" textAlign="center" maxWidth={400}>
        You are good to go! Just give us a second to load up your brand new
        dashboard...
      </Typography>
      <CircularProgress />
    </Stack>
  );
};
