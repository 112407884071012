import {
  Box,
  Button,
  Chip,
  List,
  ListItem,
  Skeleton,
  Stack,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { Label } from "../../components/Label";
import { DATE_TIME_FORMAT, switchOnAppointmentType } from "../../utils";
import {
  DevicePhoneMobileIcon,
  UserIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import { usePastBookings } from "../../services/api/booking";
import { format } from "date-fns";
import { Booking } from "../../services/types";
import { BookingPopup } from "../booking/AppointmentPopup";
import { usePopup } from "../../components/Popup";
import { ReactComponent as PastBookings } from "../../assets/images/past-bookings.svg";
import { useEffect, useState } from "react";

export const PastAppointments = () => {
  const [pastBookings, setPastBooking] = useState<Booking[]>();
  const { data, isLoading } = usePastBookings();

  useEffect(() => {
    if (data && data.length > 0) {
      const sanitizedPastBookings = data.map((p) => ({
        ...p,
        start: new Date(p.start),
        end: new Date(p.end),
      }));
      setPastBooking(sanitizedPastBookings);
    }
  }, [data]);

  return (
    <Paper sx={{ flex: 1, borderRadius: "8px" }}>
      <Typography sx={{ fontWeight: 700, padding: "24px" }}>
        Your past appointments
      </Typography>
      <Divider />
      {isLoading ? (
        <Skeleton
          height={376}
          sx={{ margin: "16px", transform: "none" }}
          variant="rounded"
          animation="wave"
        />
      ) : (
        <>
          {pastBookings && pastBookings.length > 0 ? (
            <Stack p={2} pt={0} pb={3}>
              <List sx={{ maxHeight: "376px", overflowY: "auto" }}>
                {pastBookings.map((booking) => (
                  <PastBooking key={booking.id} booking={booking} />
                ))}
              </List>
            </Stack>
          ) : (
            <Stack sx={{ alignItems: "center", p: 4 }}>
              <Box sx={{ p: 4 }}>
                <PastBookings />
              </Box>
              <Typography variant="h5" textAlign="center">
                You don't have any past appointments
              </Typography>
              <Typography textAlign="center">
                When you complete appointments they will be shown here.
              </Typography>
            </Stack>
          )}
        </>
      )}
    </Paper>
  );
};

const PastBooking = ({ booking }: { booking: Booking }) => {
  const popover = usePopup();

  const click = (target: HTMLElement) => {
    console.log(target);
    if (target) {
      popover?.show(target, <BookingPopup booking={booking} />);
    }
  };

  return (
    <ListItem>
      <Stack
        direction="row"
        sx={{ justifyContent: "space-between", width: "100%" }}
      >
        <Stack>
          <Label>
            {booking.userFirstName} {booking.userLastName}
          </Label>
          <Typography>{format(booking.start, DATE_TIME_FORMAT)}</Typography>
          <Stack direction="row">
            {switchOnAppointmentType(booking, {
              video: (
                <>
                  <VideoCameraIcon className="icon" />
                  &nbsp;Video
                </>
              ),
              phone: (
                <>
                  <DevicePhoneMobileIcon className="icon" />
                  &nbsp;Phone
                </>
              ),
              inPerson: (
                <>
                  <UserIcon className="icon" />
                  &nbsp;In Person
                </>
              ),
            })}
          </Stack>
        </Stack>
        <Stack justifyContent="center">
          {booking.status === "Cancelled" ? (
            <Chip color="error" label={booking.status} />
          ) : (
            <Chip color="success" label={booking.status} />
          )}
          <Button
            variant="text"
            onClick={(e) => {
              click(e.currentTarget);
            }}
          >
            View Details
          </Button>
        </Stack>
      </Stack>
      <Divider />
    </ListItem>
  );
};
