import useSWR, { KeyedMutator } from "swr";
import useSWRMutation from "swr/mutation";
import { Availability, Calendar, Duration, SyncedCalendar } from "../types";
import axios from "axios";
import { format } from "date-fns";
import { fixDates } from "../../utils";
import { fetcher } from "./fetcher";

export const useCalendarSync = () => {
  const { data, mutate, isLoading } = useSWR<SyncedCalendar[]>(
    "provider-connect/calendars",
    fetcher
  );

  const getSyncAuthUrl = async () => {
    return fetcher("provider-connect/auth-url");
  };

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const { trigger: updateCalendar, isMutating: isUpdatingCalendar } =
    useSWRMutation(
      "/provider-connect/calendars",
      (url: string, { arg }: { arg: string }) =>
        axios.post(url, { calendarId: arg, timeZone }).then(() => mutate())
    );

  const { trigger: unlinkCalendar, isMutating: isUnlinkingCalendar } =
    useSWRMutation("/provider-connect/unlink", (url: string) =>
      axios.post(url).then(() => mutate())
    );

  return {
    calendar: data,
    isLoading,
    isUpdatingCalendar,
    updateCalendar,
    isUnlinkingCalendar,
    unlinkCalendar,
    getSyncAuthUrl,
  };
};

export const useCalendarConfig = () => {
  const { data, mutate, isLoading } = useSWR<Availability>(
    "provider-configuration/work-hours",
    fetcher
  );

  const { trigger: setWorkingHours, isMutating: isSettingWorkHours } =
    useSWRMutation(
      "/provider-configuration/work-hours",
      (url: string, { arg }: { arg: Availability }) =>
        axios.post(url, arg).then(() => mutate())
    );

  return {
    config: data,
    isLoading,
    isSettingWorkHours,
    setWorkingHours,
  };
};

export const useCalendar = (startDate: Date, endDate: Date) => {
  const { data, isLoading, error, mutate } = useSWR<Calendar>(
    `provider-calendar?fromDate=${format(
      startDate,
      "yyyy-MM-dd"
    )}&toDate=${format(endDate, "yyyy-MM-dd")}`,
    async (url) => {
      const data = fixDates(await fetcher(url), [
        {
          availableDates: ["date", { times: ["start", "end"] }],
          events: ["start", "end"],
        },
      ]) as Calendar;

      return data;
    }
  );

  return {
    calendar: data,
    isLoading,
    error,
    mutate,
  };
};

export const useCalendarEvent = (mutate: KeyedMutator<Calendar>) => {
  const { trigger: blockCalendarEvent, isMutating: isBlocking } =
    useSWRMutation(
      "/provider-event",
      (url: string, { arg }: { arg: Duration }) =>
        axios.post(url, { start: arg.start, end: arg.end }).then(() => mutate())
    );

  const { trigger: removeCalendarEvent, isMutating: isRemoving } =
    useSWRMutation("/provider-event", (url: string, { arg }: { arg: string }) =>
      axios.delete(`${url}/${arg}`).then(() => mutate())
    );

  return {
    isBlocking,
    isRemoving,
    blockCalendarEvent,
    removeCalendarEvent,
  };
};
