import { LoadingButton } from "@mui/lab";
import Close from "@mui/icons-material/Close";
import {
  Box,
  Container,
  IconButton,
  List,
  ListItem,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";

interface LinkCalendarPopupsProps {
  openSyncWindow: () => void;
  isLinking: boolean;
}

const macOSInstructions = [
  "Open the Safari browser on your Mac.",
  'Click on "Safari" in the top menu bar.',
  'Select "Preferences" or "Settings" from the dropdown menu.',
  'In the Preferences or Settings window, go to the "Websites" tab.',
  'On the left-hand sidebar, select "Pop-up Windows."',
  'You will see a list of websites on the right side. To enable pop-ups for a specific website, click the dropdown next to it and select "Allow."',
  'To globally enable pop-ups for all websites, set the "When visiting other websites" dropdown to "Allow."',
  "Close the Preferences window.",
];

const iOSInstructions = [
  'Open the "Settings" app on your iOS device.',
  'Scroll down and tap on "Safari."',
  'In the Safari settings, find the toggle for "Block Pop-ups"',
  "Toggle the switch to disable Block Pop-ups. It will turn grey when disabled.",
  "You have now enabled pop-ups in Safari on your iOS device.",
];

const LinkCalendarPopups = ({
  openSyncWindow,
  isLinking,
}: LinkCalendarPopupsProps) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = () => setIsOpenModal(true);
  const closeModal = () => setIsOpenModal(false);

  const handleLinkOnClick = useCallback(async () => {
    closeModal();
    openSyncWindow();
  }, [openSyncWindow]);

  const renderInstructions = (instructions: Array<string>, key: string) => {
    return instructions.map((instruction, index) => (
      <ListItem key={`${key}-${index}`} sx={{ display: "list-item" }}>
        {instruction}
      </ListItem>
    ));
  };

  const ua = navigator.userAgent.toLowerCase();
  const isSafari = ua.includes("safari") && !ua.includes("chrome");
  const isIOS = ua.includes("ios");

  return (
    <>
      <LoadingButton
        onClick={openModal}
        loading={isLinking}
        color="secondary"
        size="large"
        variant="contained"
      >
        Link your Calendar
      </LoadingButton>
      <Modal open={isOpenModal} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            bgcolor: (t) => t.palette.background.paper,
            transform: "translate(-50%, -50%)",
            borderRadius: "4px",
            textAlign: "center",
            maxHeight: "100%",
            overflow: "auto",
            width: "100%",
            maxWidth: "600px",
          }}
        >
          <Stack alignItems="flex-end" p={1} pb={0}>
            <IconButton onClick={closeModal}>
              <Close />
            </IconButton>
          </Stack>
          <Stack spacing={2} p={6} pt={0} pb={4}>
            <Typography variant="h6" gutterBottom>
              Enable pop-ups to link your calendar.
            </Typography>
            {isSafari ? (
              <Container sx={{ borderTop: 1, borderColor: "divider" }}>
                <List sx={{ listStyleType: "decimal" }}>
                  {renderInstructions(
                    isIOS ? iOSInstructions : macOSInstructions,
                    isIOS ? "iOS" : "macOS"
                  )}
                </List>
              </Container>
            ) : (
              <br />
            )}
            <LoadingButton
              onClick={handleLinkOnClick}
              color="secondary"
              variant="contained"
              size="large"
            >
              Okay
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default LinkCalendarPopups;
