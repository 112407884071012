import { Box } from "@mui/material";
import { diffInPercent } from "../../utils";
import { useEffect, useState } from "react";
import { startOfToday } from "date-fns";

export const TimeBar = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 60000);
    return () => clearInterval(timer);
  });

  return (
    <Box
      id="currentHour"
      sx={{
        backgroundColor: (t) => t.palette.calendar.timeline,
        borderRadius: "2px",
        height: "2px",
        position: "absolute",
        zIndex: 999,
        width: "100%",
        top: diffInPercent(time, startOfToday()),
        pointerEvents: "none",
      }}
    >
      <Box
        sx={{
          width: "10px",
          height: "10px",
          border: "solid 1px",
          borderRadius: "50%",
          borderColor: (t) => t.palette.calendar.timeline,
          backgroundColor: (t) => t.palette.calendar.timeline,
          position: "absolute",
          zIndex: 999,
          left: "-5px",
          top: "-4px",
          pointerEvents: "none",
        }}
      />
    </Box>
  );
};
