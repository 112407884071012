import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Booking, Calendar } from "../../services/types";
import { Details } from "./Details";
import { Reschedule } from "./Reschedule";
import { Cancel } from "./Cancel";
import { usePopup } from "../../components/Popup";
import Loading from "./Loading";
import { KeyedMutator } from "swr";

const states = {
  Details: (booking: Booking, setState: (newState: States) => void) => (
    <Details booking={booking} setState={setState} />
  ),
  Reschedule: (booking: Booking, setState: (newState: States) => void) => (
    <Reschedule booking={booking} setState={setState} />
  ),
  Cancel: (booking: Booking, setState: (newState: States) => void) => (
    <Cancel booking={booking} setState={setState} />
  ),
  Loading: (booking: Booking, setState: (newState: States) => void) => (
    <Loading booking={booking} setState={setState} />
  ),
};

export type States = "Details" | "Reschedule" | "Cancel" | "Loading";

export const Appointment = ({
  booking,
  onClose,
}: {
  booking: Booking;
  onClose?: KeyedMutator<Calendar>;
}) => {
  const [state, setState] = useState<States>("Details");
  const { reposition, close } = usePopup();

  useEffect(() => {
    reposition();
    if (state === "Loading") {
      if (onClose) {
        onClose();
      }
      close();
    }
  }, [booking, state, onClose, reposition, close]);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        overflow: "none",
      }}
    >
      {states[state](booking, setState)}
    </Box>
  );
};
