import {
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

export const OutOfOffice = () => {
  return (
    <Paper sx={{ p: 2 }}>
      <Typography gutterBottom variant="h6" component="div">
        Out of office breaks
      </Typography>
      <Divider />
      <Stack sx={{ rowGap: 2, mt: 2 }}>
        <Typography textAlign="center">
          You can set an out of office break to prevent new bookings being made
          for those dates, overriding your default availability. Your
          pre-existing bookings will not be affected.
        </Typography>
      </Stack>
    </Paper>
  );
};
