import {
  Alert,
  Box,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { addDays, addHours, format, isSameDay, startOfToday } from "date-fns";
import { useEffect, useRef } from "react";
import { useCalendar } from "../../services/api";
import { range } from "../../utils";
import SimpleBar from "simplebar-react";
import { CalendarDay } from "./CalendarDay";

const now = new Date();
export const hours = range(24);

export function CalendarDays({
  startDate,
  endDate,
  dateSpan,
}: {
  startDate: Date;
  endDate: Date;
  dateSpan: number;
}) {
  const { calendar, isLoading, error, mutate } = useCalendar(
    startDate,
    endDate
  );

  const calendarRef = useRef<HTMLElement>();
  const today = startOfToday();

  // Scroll the current hour into view
  useEffect(() => {
    const timer = setTimeout(() => {
      const hour = new Date().getHours();

      // scroll only the calendar component not the whole page
      const position = document.getElementById(`h-${hour}`)?.offsetTop;
      if (calendarRef && calendarRef.current && position) {
        calendarRef.current.scroll(0, position);
      }
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const days = range(dateSpan, (i) => {
    const date = addDays(startDate, i) as Date;
    const calendarDay = calendar?.availableDates.find((d) =>
      isSameDay(d.date, date)
    );

    return {
      date,
      availableSlots: calendarDay?.times ?? [],
      events: calendar?.events.filter((e) => isSameDay(e.start, date)),
    };
  });

  return (
    <div>
      <Snackbar
        open={error}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error connecting to the server
        </Alert>
      </Snackbar>
      <Stack direction="row" spacing={0.5}>
        <Stack minWidth={60}>
          <Box height={100}></Box>
        </Stack>
        {days.map((day, i) => (
          <Stack
            key={i}
            alignItems="center"
            m={0}
            sx={{
              borderLeft: "1px solid",
              borderRight: "1px solid",
              borderColor: "divider",
              width: "100%",
            }}
          >
            <Box minHeight={100}>
              <Box>{format(day.date, "ccc")}</Box>
              <Box
                sx={{
                  backgroundColor: (t) =>
                    isSameDay(day.date, now)
                      ? t.palette.primary.main
                      : t.palette.action.disabledBackground,
                  color: (t) =>
                    isSameDay(day.date, now)
                      ? t.palette.primary.contrastText
                      : t.palette.action.disabled,
                  p: 1,
                  borderRadius: 1,
                }}
              >
                {format(day.date, "dd")}
              </Box>
            </Box>
          </Stack>
        ))}
      </Stack>
      <SimpleBar
        scrollableNodeProps={{ ref: calendarRef }}
        style={{ maxHeight: "400px", overflowX: "hidden" }}
      >
        <Stack direction="row" position="relative" spacing={0.5}>
          <Stack minWidth={60}>
            <Box height={1200}>
              {hours.map((h) => (
                <Box
                  key={h}
                  id={`h-${h}`}
                  height="4.166%"
                  whiteSpace="nowrap"
                  textAlign="right"
                >
                  {Boolean(h) && (
                    <Typography
                      variant="caption"
                      top="-.75rem"
                      position="relative"
                    >
                      {format(addHours(today, h), "p")}
                    </Typography>
                  )}
                </Box>
              ))}
            </Box>
          </Stack>
          {isLoading
            ? days.map((_day, i) => (
                <Box
                  key={i}
                  alignItems="center"
                  m={0}
                  sx={{
                    borderLeft: "1px solid",
                    borderRight: "1px solid",
                    borderColor: "divider",
                    p: 0.5,
                    width: "100%",
                  }}
                >
                  <Skeleton
                    key={i}
                    variant="rectangular"
                    width="100%"
                    height="100%"
                  />
                </Box>
              ))
            : days.map((day, i) => (
                <CalendarDay key={i} day={day} mutate={mutate} />
              ))}
        </Stack>
      </SimpleBar>
    </div>
  );
}
