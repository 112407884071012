import {
    Grid,
    Paper,
    Box,
    Stack,
  } from "@mui/material";
import { PropsWithChildren } from "react";
import { Steps } from "./Steps";
import { OnboardingImageUrl } from "../../theme";
  
  export const Layout = ({children}: PropsWithChildren) => {
    return (
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square overflow="auto">
          <Box
            sx={{
              my: {xs: 1, md: 8},
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack spacing={4} alignItems="center" width="100%">
              <Steps />
              {children}
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundRepeat: "no-repeat",
            backgroundColor: "primary.main",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${OnboardingImageUrl})`
          }}
        />
      </Grid>
    );
  };
  