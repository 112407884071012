import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import { Onboarding } from "./features/onboarding";
import { routes as onBoardingRoutes } from "./features/onboarding/Routes";
import { routes as dashboardRoutes } from "./features/Routes";
import { useCalendarConfig } from "./services/api";
import { Layout } from "./components/Layout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main />,
  },
  {
    path: "/onboarding",
    element: <Onboarding />,
    children: onBoardingRoutes,
  },
  {
    path: "/dashboard",
    element: <Layout />,
    children: dashboardRoutes,
  },
]);

function Main() {
  const { config } = useCalendarConfig();
  const onBoarded = (config?.workingHours ?? []).length > 0;

  return config ? (
    onBoarded ? (
      <Navigate to="/dashboard" />
    ) : (
      <Navigate to="/onboarding" />
    )
  ) : (
    <></>
  );
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
