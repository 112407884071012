import useSWR from "swr";
import { Profile } from "../types";
import { fetcher } from "./fetcher";

export const useProfile = () => {
  const { data, isLoading } = useSWR<Profile>("provider/profile", fetcher);

  return {
    provider: data,
    isLoading,
  };
};
