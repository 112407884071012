import React from "react";
import { Typography } from "@mui/material";
import { SyncedCalendar } from "../../services/types";
import { format } from "date-fns";
import { DATE_TIME_FORMAT } from "../../utils";

const LastUpdated = ({ calendar }: { calendar?: SyncedCalendar[] }) => {
  if (!calendar) {
    return <></>;
  }

  const primaryCalendar = calendar.filter((c) => c.isPrimary)[0];

  if (!primaryCalendar) {
    return <></>;
  }

  const { lastSyncedUtc } = primaryCalendar;

  return (
    <Typography display="flex" alignItems="center">
      {lastSyncedUtc &&
        `Last update received ${format(
          new Date(lastSyncedUtc),
          DATE_TIME_FORMAT
        )}`}
    </Typography>
  );
};

export default LastUpdated;
