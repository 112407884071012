import { Box } from "@mui/material";
import { areIntervalsOverlapping } from "date-fns";
import { CalendarEvent } from "../../services/types";
import { diffInPercent } from "../../utils";

export function Externals({
  events,
  date,
}: {
  events?: CalendarEvent[];
  date: Date;
}) {
  const externals = events
    ?.filter((e) => e.type === "External")
    .reduce((a, b) => {
      if (a.length === 0) {
        a.push(b);
      } else {
        var last = a[a.length - 1];
        if (
          areIntervalsOverlapping(last, b) ||
          last.end.getTime() === b.start.getTime()
        ) {
          last.end = b.end > last.end ? b.end : last.end;
        } else {
          a.push(b);
        }
      }
      return a;
    }, [] as CalendarEvent[]);

  return (
    <>
      {externals?.map((event, i) => (
        <Box
          key={i}
          sx={{
            top: diffInPercent(event.start, date),
            height: diffInPercent(event.end, event.start),
            px: 0.5,
            width: "100%",
            position: "absolute",
          }}
        >
          <Box
            borderRadius={1}
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: (t) => t.palette.calendar.unavailable.default,
              color: (t) => t.palette.calendar.booking.contrastText,
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>Unavailable</div>
          </Box>
        </Box>
      ))}
    </>
  );
}
