import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Label } from "../../components/Label";
import LinkCalendarPopups from "../../components/LinkCalendarPopups";
import { useCalendarConfig, useCalendarSync } from "../../services/api";
import { sessionLength } from "../../theme";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { SyncedCalendar } from "../../services/types";
import { LoadingButton } from "@mui/lab";
import { useCronofyAutoSync } from "../../hooks/useCronofy";
import { CalendarDaysIcon, XMarkIcon } from "@heroicons/react/24/outline";

export const ScheduleManagement = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, calendar, updateCalendar, isUpdatingCalendar } =
    useCalendarSync();
  const { config, setWorkingHours, isSettingWorkHours } = useCalendarConfig();

  const { isLinking, openSyncWindow, unsync, isUnsyncing } =
    useCronofyAutoSync();

  const [primarySyncedCalendar, setPrimarySyncedCalendar] =
    useState<SyncedCalendar>();
  const [bufferTime, setBufferTime] = useState("");

  const primaryCalendarChanged =
    calendar?.filter((item) => item.isPrimary)[0]?.id !==
    primarySyncedCalendar?.id;
  const bufferTimeChanged = config?.workingHours[0]?.bufferTime !== bufferTime;

  useEffect(() => {
    if (config && config.workingHours.length > 0) {
      const configBufferTime = config?.workingHours[0]?.bufferTime;
      setBufferTime(configBufferTime);
    }
  }, [config]);

  useEffect(() => {
    if (calendar) {
      const primaryCalendar = calendar.filter((item) => item.isPrimary);

      if (primaryCalendar[0]) {
        setPrimarySyncedCalendar(primaryCalendar[0]);
      }
    }
  }, [calendar]);

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const { value } = event.target;
      const primaryCalendar = calendar?.filter((item) => item.id === value);
      if (primaryCalendar && primaryCalendar[0].id) {
        setPrimarySyncedCalendar(primaryCalendar[0]);
      }
    },
    [calendar, setPrimarySyncedCalendar]
  );

  const handleUnsync = useCallback(async () => {
    await unsync();
    setPrimarySyncedCalendar(undefined);
  }, [unsync]);

  const asyncUpdateCalendar = useCallback(async () => {
    if (primarySyncedCalendar) {
      await updateCalendar(primarySyncedCalendar.id as string);
      enqueueSnackbar("Primary Calendar updated", { variant: "success" });
    }
  }, [enqueueSnackbar, primarySyncedCalendar, updateCalendar]);

  const asyncUpdateWorkingHours = useCallback(async () => {
    if (config) {
      await setWorkingHours({
        ...config,
        workingHours: config.workingHours.map((w) => ({
          ...w,
          bufferTime: bufferTime!,
          sessionLength,
        })),
      });
    }
    enqueueSnackbar("Buffer updated", { variant: "success" });
  }, [bufferTime, config, enqueueSnackbar, setWorkingHours]);

  const saveChanges = useCallback(() => {
    if (primaryCalendarChanged) {
      asyncUpdateCalendar();
    }
    if (bufferTimeChanged) {
      asyncUpdateWorkingHours();
    }
  }, [
    asyncUpdateCalendar,
    asyncUpdateWorkingHours,
    bufferTimeChanged,
    primaryCalendarChanged,
  ]);

  return (
    <Paper sx={{ p: 2 }}>
      <Typography gutterBottom variant="h6" component="div">
        Schedule management
      </Typography>
      <Divider />
      {isLoading ? (
        <Skeleton
          width={"100%"}
          height={332}
          sx={{ margin: "16px 0px", transform: "none" }}
          variant="rounded"
          animation="wave"
        />
      ) : (
        <Stack sx={{ rowGap: 2, mt: 2 }}>
          {primarySyncedCalendar && !isLinking ? (
            <Stack spacing={2}>
              <Label>Synced main calendar</Label>
              <TextField
                value={primarySyncedCalendar.profile}
                fullWidth
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarDaysIcon className="icon" />
                    </InputAdornment>
                  ),
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <FormControl sx={{ minWidth: "216px", marginRight: "24px" }}>
                  <InputLabel id="primarySyncedCalendar">
                    Primary Calendar
                  </InputLabel>
                  <Select
                    labelId="primarySyncedCalendar"
                    id="primarySyncedCalendar"
                    value={primarySyncedCalendar.id}
                    label="Primary Calendar"
                    onChange={handleChange}
                    fullWidth
                  >
                    {calendar?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <LoadingButton
                  color="error"
                  startIcon={<XMarkIcon className="icon" />}
                  onClick={handleUnsync}
                  variant="outlined"
                  loading={isUnsyncing}
                  disabled={isSettingWorkHours || isUpdatingCalendar}
                >
                  Unsync this calendar
                </LoadingButton>
              </Box>
            </Stack>
          ) : (
            <>
              <Typography component="h1" variant="h5" gutterBottom>
                Sync Calendar
              </Typography>
              <Typography component="p" textAlign="center">
                This will allow you to keep your Ignite schedule up to date
                automatically and have complete visibility of all your
                appointments.
              </Typography>
              <LinkCalendarPopups
                openSyncWindow={openSyncWindow}
                isLinking={isLinking}
              />
            </>
          )}
          <Box>
            <Label>Appointment buffer time</Label>
            <FormControl fullWidth sx={{ minWidth: 130 }}>
              <Select
                labelId="to-label"
                value={bufferTime}
                onChange={(e) => setBufferTime(e.target.value)}
              >
                <MenuItem value="00:00:00">None</MenuItem>
                <MenuItem value="00:05:00">5 Minutes</MenuItem>
                <MenuItem value="00:10:00">10 Minutes</MenuItem>
                <MenuItem value="00:15:00">15 Minutes</MenuItem>
                <MenuItem value="00:30:00">30 Minutes</MenuItem>
                <MenuItem value="00:45:00">45 Minutes</MenuItem>
                <MenuItem value="01:00:00">1 Hour</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Divider />
          <LoadingButton
            onClick={saveChanges}
            color="secondary"
            variant="outlined"
            loading={isSettingWorkHours || isUpdatingCalendar}
            disabled={
              (!primaryCalendarChanged && !bufferTimeChanged) || isUnsyncing
            }
          >
            Save changes
          </LoadingButton>
        </Stack>
      )}
    </Paper>
  );
};
