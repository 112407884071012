import { IconButton, MobileStepper } from "@mui/material"
import {routes} from "./Routes"
import { useLocation } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

export const Steps = () => {
    const location = useLocation();

    return (<MobileStepper
        variant="dots"
        steps={routes.length}
        position="top"
        activeStep={routes.findIndex(r => location.pathname === "/onboarding/" + r.path)}
        sx={{ maxWidth: 400, flexGrow: 1, backgroundColor: t => t.palette.background.paper }} 
        backButton={<IconButton size="small" onClick={() => window.history.back()}><ArrowLeftIcon className="icon" /></IconButton>} nextButton={<></>} />)
}