import { Divider, Paper, Stack, useMediaQuery, useTheme } from "@mui/material";
import { addDays, startOfToday } from "date-fns";
import { useState } from "react";
import { CalendarToolBar } from "./CalendarToolbar";
import "simplebar-react/dist/simplebar.min.css";
import { CalendarDays } from "./CalendarDays";
import { weekStart } from "../../utils";
import { SyncStatus } from "./SyncStatus";
import LastUpdated from "./LastUpdated";
import { useCalendarSync } from "../../services/api";

export const Calendar = () => {
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up("sm"));
  const [startDate, setStartDate] = useState<Date>(
    isMedium ? weekStart : startOfToday()
  );
  const dateSpan = isMedium ? 7 : 3;
  const endDate = addDays(startDate, dateSpan);
  const { calendar } = useCalendarSync();

  return (
    <Paper sx={{ p: 2 }}>
      <Stack spacing={2}>
        <CalendarToolBar
          startDate={startDate}
          endDate={endDate}
          setDate={setStartDate}
          dateSpan={dateSpan}
        />
        <Divider />
        <CalendarDays {...{ startDate, endDate }} dateSpan={dateSpan} />
        <Divider />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <SyncStatus calendar={calendar} />
          <LastUpdated calendar={calendar} />
        </Stack>
      </Stack>
    </Paper>
  );
};
