import {
  ArrowTopRightOnSquareIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  UserIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  Chip,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { Booking } from "../../services/types";
import { Label } from "../../components/Label";
import { switchOnAppointmentType } from "../../utils";
import { States } from "./Appointment";
import { PropsWithChildren, ReactNode } from "react";
import { supportEmail } from "../../theme";
import { useProfile } from "../../services/api";

export function Details({
  booking,
  setState,
}: {
  booking: Booking;
  setState: (newState: States) => void;
}) {
  const { provider } = useProfile();

  const handleOnClick = () => {
    if (provider) {
      const { meetingRoom } = booking;
      const { firstName } = provider;
      window.open(
        `${process.env.REACT_APP_VIDEO_SITE}?userid=${firstName}&roomid=${meetingRoom}`,
        "_blank"
      );
    }
  };

  return (
    <Stack>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5">
            {booking.userFirstName} {booking.userLastName}
          </Typography>
          <Typography display="flex" alignItems="center">
            {switchOnAppointmentType(booking, {
              video: (
                <>
                  <VideoCameraIcon className="icon" />
                  &nbsp;Video
                </>
              ),
              phone: (
                <>
                  <DevicePhoneMobileIcon className="icon" />
                  &nbsp;Phone
                </>
              ),
              inPerson: (
                <>
                  <UserIcon className="icon" />
                  &nbsp;In Person
                </>
              ),
            })}
          </Typography>
        </Stack>
        {booking.status !== "Booked" && (
          <Box>
            <Chip color="error" label={booking.status} />
          </Box>
        )}
        <Stack>
          <Label>Client Request</Label>
          <Typography variant="body1">“{booking.appointmentNote}”</Typography>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between" sx={{ gap: 1 }}>
          <Stack sx={{ flex: 1 }}>
            <Label>Date & Time</Label>
            <Typography variant="body1">
              {format(booking.start, "EEE d MMM yyyy")},<br />
              {format(booking.start, "hh:mm aa")} -{" "}
              {format(booking.end, "hh:mm aa")}
            </Typography>
          </Stack>
          <Stack sx={{ flex: 1 }}>
            {switchOnAppointmentType(booking, {
              video: (
                <>
                  <Label>Location</Label>
                  <Button
                    variant="outlined"
                    size="large"
                    color="secondary"
                    endIcon={<ArrowTopRightOnSquareIcon className="icon" />}
                    onClick={handleOnClick}
                  >
                    Virtual Meeting
                  </Button>
                </>
              ),
              phone: (
                <>
                  <Label>Phone Number</Label>
                  <Button
                    variant="outlined"
                    size="large"
                    color="secondary"
                    startIcon={<DevicePhoneMobileIcon className="icon" />}
                    component="a"
                    href={"tel:" + booking.userPhone}
                  >
                    {booking.userPhone}
                  </Button>
                </>
              ),
              inPerson: (
                <>
                  <Label>Location</Label>
                  {/* TODO: Address */}
                </>
              ),
            })}
          </Stack>
        </Stack>
        <Stack>
          <Label>Contact</Label>
          <Stack direction={{ sx: "column", sm: "row" }} sx={{ gap: 1 }}>
            <ContactButton
              icon={<EnvelopeIcon className="icon" />}
              href={"mailto:" + booking.userEmail}
            >
              {booking.userEmail}
            </ContactButton>
            {booking.userPhone && (
              <ContactButton
                icon={<DevicePhoneMobileIcon className="icon" />}
                href={"tel:" + booking.userPhone}
              >
                {booking.userPhone}
              </ContactButton>
            )}
          </Stack>
        </Stack>
      </Stack>

      <Actions {...{ setState, booking }} />
    </Stack>
  );
}

const ContactButton = ({
  children,
  icon,
  href,
}: { icon: ReactNode; href: string } & PropsWithChildren) => {
  return (
    <Button
      sx={{
        flex: 1,
        backgroundColor: (t) => t.palette.background.default,
        color: (t) => t.palette.text.primary,
        textTransform: "none",
        "&:hover": {
          backgroundColor: (t) => t.palette.action.hover,
        },
        "& svg": {
          color: (t) => t.palette.secondary.main,
        },
      }}
      variant="contained"
      disableElevation
      startIcon={icon}
      component="a"
      href={href}
    >
      {children}
    </Button>
  );
};

function Actions({
  setState,
  booking,
}: {
  setState: (state: States) => void;
  booking: Booking;
}) {
  function Reschedule() {
    setState("Reschedule");
  }

  function Cancel() {
    setState("Cancel");
  }

  return booking.status !== "Booked" || booking.start < new Date() ? (
    <Stack
      direction="row"
      sx={{
        borderTop: "1px solid",
        borderColor: (t) => t.palette.divider,
        justifyContent: "space-around",
        position: "relative",
      }}
    >
      <Button
        sx={{ flex: 1, m: 2 }}
        component={Link}
        href={`mailto:${supportEmail}`}
      >
        Request Support
      </Button>
    </Stack>
  ) : (
    <Stack
      direction="row"
      sx={{
        borderTop: "1px solid",
        borderColor: (t) => t.palette.divider,
        justifyContent: "space-around",
        position: "relative",
      }}
    >
      <Button sx={{ flex: 1, m: 2 }} onClick={Reschedule}>
        Request Reschedule
      </Button>
      <Divider sx={{ height: "unset" }} orientation="vertical" />
      <Button sx={{ flex: 1, m: 2 }} color="error" onClick={Cancel}>
        Cancel Booking
      </Button>
    </Stack>
  );
}
