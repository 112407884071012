import { Box } from "@mui/material";
import { areIntervalsOverlapping } from "date-fns";
import { Calendar, CalendarEvent } from "../../services/types";
import { diffInPercent } from "../../utils";
import { KeyedMutator } from "swr";
import { useCalendarEvent } from "../../services/api";

const BlockedSlot = ({
  event,
  date,
  mutate,
}: {
  event: CalendarEvent;
  date: Date;
  mutate: KeyedMutator<Calendar>;
}) => {
  const { isRemoving, removeCalendarEvent } = useCalendarEvent(mutate);

  const handleOnClick = (id: string) => {
    if (id) {
      removeCalendarEvent(id);
    }
  };

  return (
    <Box
      sx={{
        top: diffInPercent(event.start, date),
        height: diffInPercent(event.end, event.start),
        px: 0.5,
        width: "100%",
        position: "absolute",
      }}
    >
      {isRemoving ? (
        <Box
          borderRadius={1}
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: (t) => t.palette.calendar.loading,
          }}
        />
      ) : (
        <Box
          borderRadius={1}
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: (t) => t.palette.calendar.blocked.default,
            "&:hover": {
              backgroundColor: (t) => t.palette.calendar.blocked.hover,
              cursor: "pointer",
            },
            color: (t) => t.palette.calendar.booking.contrastText,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => handleOnClick(`${event.id}`)}
        >
          <div>Blocked</div>
        </Box>
      )}
    </Box>
  );
};

export function BlockedSlots({
  events,
  date,
  mutate,
}: {
  events?: CalendarEvent[];
  date: Date;
  mutate: KeyedMutator<Calendar>;
}) {
  const externals = events
    ?.filter((e) => e.type === "Blocked")
    .reduce((a, b) => {
      if (a.length === 0) {
        a.push(b);
      } else {
        var last = a[a.length - 1];
        if (
          areIntervalsOverlapping(last, b) ||
          last.end.getTime() === b.start.getTime()
        ) {
          last.end = b.end > last.end ? b.end : last.end;
        } else {
          a.push(b);
        }
      }
      return a;
    }, [] as CalendarEvent[]);

  return (
    <>
      {externals?.map((event, i) => (
        <BlockedSlot key={i} event={event} mutate={mutate} date={date} />
      ))}
    </>
  );
}
