import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { Booking } from "../../services/types";
import { States } from "./Appointment";

const Loading = ({
  booking,
  setState,
}: {
  booking: Booking;
  setState: (newState: States) => void;
}) => {
  useEffect(() => {
    if (booking) {
      setTimeout(() => {
        setState("Details");
      }, 1000);
    }
  }, [booking, setState]);

  return (
    <Skeleton
      height={376}
      sx={{ margin: "16px", transform: "none" }}
      variant="rounded"
      animation="wave"
    />
  );
};

export default Loading;
