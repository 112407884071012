import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { Stack, Typography, IconButton, Box } from "@mui/material";
import { addDays, format } from "date-fns";

export const CalendarToolBar = ({
    startDate,
    endDate,
    setDate,
    dateSpan
  }: {
    startDate: Date;
    endDate: Date;
    setDate: (date: Date) => void;
    dateSpan: number;
  }) => {
    
    return (
      <Stack direction={{sx: "column", sm: "row"}} alignItems={{md: "center"}} justifyContent="space-between">
        <Typography variant="subtitle1" component="h5">
          Your Ignite booking calendar
        </Typography>
        <Stack direction="row">
          <Box sx={{flexGrow: 1}}></Box>
          <Stack direction="row" alignItems="center" sx={{color: t => t.palette.secondary.main}}>
            <IconButton color="secondary" onClick={() => setDate(addDays(startDate, -dateSpan))}>
              <ChevronLeftIcon className="icon" />
            </IconButton>
            <Box>
              {format(startDate, "dd MMM")} - {format(endDate, "dd MMM")}
            </Box>
            <IconButton color="secondary" onClick={() => setDate(addDays(startDate, dateSpan))}>
              <ChevronRightIcon className="icon" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
    );
  };