import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import { fetcher } from "./fetcher";
import { Booking, CalendarEvent } from "../types";
import axios from "axios";

interface ModifyBookingPayload {
  bookingId: number;
  eventId: string;
  noteToUser: string;
}

export const useNextBooking = () => {
  const { data, isLoading } = useSWR<Booking | Booking[]>(
    "/provider-booking/next",
    fetcher
  );

  return {
    data: data && Array.isArray(data) ? data[0] : data,
    isLoading,
  };
};

export const usePastBookings = () => {
  const { data, isLoading } = useSWR<Booking[]>(
    "/provider-booking/past",
    fetcher
  );

  return {
    data,
    isLoading,
  };
};

export const useBookings = () => {
  const cancel = (
    bookingId: number,
    eventId: string,
    noteToUser: string,
    key: string
  ) => {
    return axios
      .post(key, {
        bookingId,
        noteToUser,
      })
      .then(() => {
        mutate("/provider-booking/next");
        mutate("/provider-booking/past");
        mutate(`/provider-booking/${eventId}`);
      });
  };

  const { trigger: cancelBooking, isMutating: isCancellingBooking } =
    useSWRMutation(
      "/provider-booking/cancel",
      (url: string, { arg }: { arg: ModifyBookingPayload }) =>
        cancel(arg.bookingId, arg.eventId, arg.noteToUser, url)
    );

  const { trigger: rescheduleBooking, isMutating: isReschedulingBooking } =
    useSWRMutation(
      "/provider-booking/reschedule",
      (url: string, { arg }: { arg: ModifyBookingPayload }) =>
        cancel(arg.bookingId, arg.eventId, arg.noteToUser, url)
    );

  return {
    rescheduleBooking,
    isReschedulingBooking,
    cancelBooking,
    isCancellingBooking,
  };
};

export const useBooking = (event: CalendarEvent) => {
  const { data, isLoading, mutate } = useSWR<Booking>(
    `/provider-booking/${event.id}`,
    async (url) => {
      await fetcher(url).then((booking) => {
        mutate(booking, { revalidate: false, populateCache: true });
      });
      return {
        id: -1,
        appointmentType: event.appointmentType,
        appointmentNote: "",
        appointmentTime: event.start,
        end: event.end,
        location: "",
        start: event.start,
        status: "",
        userEmail: event.clientEmail,
        userFirstName: event.clientFirstName,
        userLastName: event.clientLastName,
        userPhone: event.clientPhone,
        userId: "",
      } as Booking;
    }
  );

  return {
    booking: data
      ? { ...data, start: new Date(data.start), end: new Date(data.end) }
      : null,
    isLoading,
  };
};
