import { Availability } from "./Availability";
import { Complete } from "./Complete";
import { LinkCalendar } from "./LinkCalendar";
import { Welcome } from "./Welcome";

export const routes = [
  {
    index: true,
    path: "",
    element: <Welcome />
  },
  {
    path: "link-calendar",
    element: <LinkCalendar />
  },
  {
    path: "availability",
    element: <Availability />
  },
  {
    path: "complete",
    element: <Complete />
  }
]