import {
  Alert,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Booking } from "../../services/types";
import { States } from "./Appointment";
import {
  VideoCameraIcon,
  DevicePhoneMobileIcon,
  UserIcon,
  XCircleIcon,
  ArrowLongRightIcon,
} from "@heroicons/react/24/outline";
import { format } from "date-fns";
import { switchOnAppointmentType } from "../../utils";
import { Label } from "../../components/Label";
import { useBookings } from "../../services/api/booking";
import { useState } from "react";
import { useSnackbar } from "notistack";

export const Cancel = ({
  booking,
  setState,
}: {
  booking: Booking;
  setState: (newState: States) => void;
}) => {
  const { cancelBooking, isCancellingBooking } = useBookings();
  const [message, setMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const cancelButtonClicked = async () => {
    await cancelBooking({
      bookingId: booking.id,
      eventId: booking.eventId,
      noteToUser: message,
    });
    enqueueSnackbar(
      "This booking has been removed and a cancellation email has been sent.",
      {
        variant: "success",
      }
    );
    setState("Loading");
  };

  return (
    <Stack>
      <Stack sx={{ p: 2 }} spacing={2}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h5">
            {booking.userFirstName} {booking.userLastName}
          </Typography>
          <Typography display="flex" alignItems="center">
            {switchOnAppointmentType(booking, {
              video: (
                <>
                  <VideoCameraIcon className="icon" />
                  &nbsp;Video
                </>
              ),
              phone: (
                <>
                  <DevicePhoneMobileIcon className="icon" />
                  &nbsp;Phone
                </>
              ),
              inPerson: (
                <>
                  <UserIcon className="icon" />
                  &nbsp;In Person
                </>
              ),
            })}
          </Typography>
        </Stack>
        <Divider />
        <Stack direction="row" justifyContent="space-between" sx={{ gap: 1 }}>
          <Stack sx={{ flex: 1 }}>
            <Label>Original Date</Label>
            <Typography variant="body1">
              {format(booking.start, "EEE d MMM yyyy")},<br />
              {format(booking.start, "hh:mm aa")} -{" "}
              {format(booking.end, "hh:mm aa")}
            </Typography>
          </Stack>
          <Stack alignItems="center" direction="row">
            <ArrowLongRightIcon style={{ width: "40px" }} />
          </Stack>
          <Stack sx={{ flex: 1, justifyItems: "center" }}>
            <XCircleIcon style={{ maxHeight: "80px" }} />
          </Stack>
        </Stack>
        <Stack>
          <Label>Note to client</Label>
          <TextField
            id="outlined-multiline-flexible"
            placeholder={"Enter a message to " + booking.userFirstName + "..."}
            multiline
            maxRows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Stack>
        <Alert severity="error">
          {booking.userFirstName} will be notified that the appointment has been
          cancelled and it will be removed from your booking schedule.
        </Alert>
      </Stack>

      <Stack
        direction="row"
        sx={{
          borderTop: "1px solid",
          borderColor: (t) => t.palette.divider,
          justifyContent: "space-around",
          position: "relative",
        }}
      >
        <Button
          sx={{ flex: 1, m: 2 }}
          onClick={() => setState("Details")}
          disabled={isCancellingBooking}
        >
          Go Back
        </Button>
        <Divider sx={{ height: "unset" }} orientation="vertical" />
        <LoadingButton
          sx={{ flex: 1, m: 2 }}
          color="error"
          onClick={cancelButtonClicked}
          loading={isCancellingBooking}
        >
          Cancel Appointment
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
