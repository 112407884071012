import { isAfter } from "date-fns";
import { Calendar, Duration } from "../../services/types";
import { Box } from "@mui/material";
import { KeyedMutator } from "swr";
import { diffInPercent } from "../../utils";
import { useCalendarEvent } from "../../services/api";

const AvailableSlot = ({
  slot,
  date,
  mutate,
}: {
  slot: Duration;
  date: Date;
  mutate: KeyedMutator<Calendar>;
}) => {
  const { isBlocking, blockCalendarEvent } = useCalendarEvent(mutate);

  const handleOnClick = (slot: Duration) => {
    blockCalendarEvent({ ...slot });
  };

  return (
    <>
      {isAfter(slot.start, new Date()) && (
        <Box
          sx={{
            top: diffInPercent(slot.start, date),
            height: diffInPercent(slot.end, slot.start),
            px: 0.5,
            width: "100%",
            position: "absolute",
          }}
        >
          {isBlocking ? (
            <Box
              borderRadius={1}
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: (t) => t.palette.calendar.loading,
              }}
            />
          ) : (
            <Box
              borderRadius={1}
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: (t) => t.palette.calendar.available.default,
                "&:hover": {
                  backgroundColor: (t) => t.palette.calendar.available.hover,
                  cursor: "pointer",
                },
              }}
              onClick={() => handleOnClick(slot)}
            />
          )}
        </Box>
      )}
    </>
  );
};

interface AvailableSlotsProps {
  date: Date;
  availableSlots: Duration[];
  mutate: KeyedMutator<Calendar>;
}

const AvailableSlots = ({
  date,
  availableSlots,
  mutate,
}: AvailableSlotsProps) => {
  return (
    <>
      {availableSlots.map((slot, i) => (
        <AvailableSlot key={i} slot={slot} mutate={mutate} date={date} />
      ))}
    </>
  );
};

export default AvailableSlots;
