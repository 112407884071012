import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

import LinkCalendarPopups from "../../components/LinkCalendarPopups";
import { useCronofy } from "../../hooks/useCronofy";
import { useCallback, useEffect, useState } from "react";
import { useCalendarSync } from "../../services/api";
import { SyncedCalendar } from "../../services/types";

export const LinkCalendar = () => {
  const [primarySyncedCalendar, setPrimarySyncedCalendar] = useState<
    SyncedCalendar | undefined
  >(undefined);
  const [primaryCalendarError, setPrimaryCalendarError] = useState("");

  const { calendar, updateCalendar, isUpdatingCalendar } = useCalendarSync();
  const { isLinking, linkingSuccess, openSyncWindow } = useCronofy();
  const navigate = useNavigate();

  useEffect(() => {
    if ((calendar && calendar.length > 0 && calendar[0].id) || linkingSuccess) {
      const primaryCalendar = calendar?.filter((item) => item.isPrimary);
      if (primaryCalendar && primaryCalendar[0]) {
        setPrimarySyncedCalendar(primaryCalendar[0]);
      }
    }
  }, [calendar, linkingSuccess]);

  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      const { value } = event.target;
      const primaryCalendar = calendar?.filter((item) => item.id === value);
      if (primaryCalendar && primaryCalendar[0].id) {
        setPrimarySyncedCalendar(primaryCalendar[0]);
      }
      if (primaryCalendarError !== "") {
        setPrimaryCalendarError("");
      }
    },
    [calendar, primaryCalendarError, setPrimarySyncedCalendar]
  );

  const handleOnClick = useCallback(async () => {
    if (!primarySyncedCalendar) {
      setPrimaryCalendarError("Please select your primary calendar.");
    } else if (primaryCalendarError === "") {
      await updateCalendar(primarySyncedCalendar.id as string);
      navigate("../availability");
    }
  }, [primarySyncedCalendar, primaryCalendarError, updateCalendar, navigate]);

  if (!calendar) {
    return <></>;
  }

  return (
    <Stack spacing={2} alignItems="center" justifyItems="center" mt={2}>
      <Helmet>
        <title>Sync your main calendar</title>
      </Helmet>
      <Typography component="h1" variant="h5" gutterBottom>
        Sync Calendar
      </Typography>
      <Typography component="p" textAlign="center" sx={{ maxWidth: 400 }}>
        This will allow you to keep your Ignite schedule up to date
        automatically and have complete visibility of all your appointments.
      </Typography>
      {primarySyncedCalendar ? (
        <Stack spacing={2} sx={{ minWidth: "216px" }}>
          <FormControl error={primaryCalendarError !== ""}>
            <InputLabel id="primarySyncedCalendar">Primary Calendar</InputLabel>
            <Select
              labelId="primarySyncedCalendar"
              id="primarySyncedCalendar"
              value={primarySyncedCalendar.id}
              label="Primary Calendar"
              onChange={handleChange}
              fullWidth
            >
              {calendar.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{primaryCalendarError}</FormHelperText>
          </FormControl>
          <LoadingButton
            loading={isUpdatingCalendar}
            onClick={handleOnClick}
            color="secondary"
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Stack>
      ) : (
        <Stack spacing={2}>
          <LinkCalendarPopups
            openSyncWindow={openSyncWindow}
            isLinking={isLinking}
          />
          <Button component={Link} to="../availability" color="secondary">
            Do this later
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
