import { Box, Button, IconButton, Stack } from "@mui/material";
import { MinusIcon, PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import { TimePicker } from "@mui/x-date-pickers";
import { Control, Controller, useFieldArray } from "react-hook-form";
import { AvailabilityProps } from "./AvailablityHours";

export function WorkHours({
  day,
  control,
}: {
  day: number;
  control: Control<AvailabilityProps>;
}) {
  const { fields, append, remove } = useFieldArray({
    name: `availability.${day}.hours` as const,
    control: control,
  });

  return (
    <Stack flex={2}>
      {fields.map((hours, index) => (
        <Stack key={hours.id} direction="row" spacing={1} alignItems="center">
          <Controller
            name={`availability.${day}.hours.${index}.start` as const}
            control={control}
            render={({ field }) => <TimePicker {...field} />}
          />

          <Box justifyItems="center" sx={{ minWidth: 32 }}>
            <MinusIcon className="icon" style={{ margin: "auto" }} />
          </Box>

          <Controller
            name={`availability.${day}.hours.${index}.end` as const}
            control={control}
            render={({ field }) => <TimePicker {...field} />}
          />

          <IconButton color="error" onClick={() => remove(index)}>
            <TrashIcon className="icon" />
          </IconButton>
        </Stack>
      ))}
      <Button
        color="secondary"
        onClick={() => append({})}
        startIcon={<PlusIcon className="icon" />}
      >
        Add separate time block
      </Button>
    </Stack>
  );
}
