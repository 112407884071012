import axios from "axios";

axios.defaults.baseURL = "/api/";
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        window.location.assign("/auth/login?redirectUrl=" + window.location);
      }
    }

    return Promise.reject(error);
  }
);
export const fetcher = (url: string) => axios.get(url).then((res) => res.data);
