import {
  Avatar,
  Button,
  Chip,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useProfile } from "../../services/api";
import { MapPinIcon } from "@heroicons/react/24/outline";
import { supportEmail } from "../../theme";

export function MyServices() {
  const { provider } = useProfile();

  return (
    <Paper sx={{ p: 2, flex: 1, width: "100%" }}>
      <Typography gutterBottom variant="h6" component="div">
        My Services
      </Typography>
      <Divider />
      <Stack direction="row" alignItems="center" spacing={2} my={2}>
        <Avatar src={provider?.avatar} sx={{ width: 100, height: 100 }} />
        <div>
          <Typography variant="h5">
            {provider?.firstName + " " + provider?.lastName}
          </Typography>
          <Typography>
            {provider?.type} | {provider?.qualifications}
          </Typography>
          <Typography>
            <MapPinIcon className="icon" style={{ verticalAlign: "middle" }} />{" "}
            {provider?.address}
          </Typography>
        </div>
      </Stack>
      <Typography my={2}>{provider?.shortDescription}</Typography>
      <Divider />
      <Typography variant="subtitle1" gutterBottom marginTop={2}>
        Specialties
      </Typography>
      <Stack direction="row" marginBottom={2}>
        {provider?.specialties.map((s) => (
          <Chip
            key={s}
            label={s}
            sx={{ borderRadius: 2, marginRight: "8px" }}
            variant="outlined"
          />
        ))}
      </Stack>
      <Divider />
      <Typography variant="subtitle1" gutterBottom mt={2}>
        Biography
      </Typography>
      <Typography variant="body1" mb={2}>
        {provider?.biography}
      </Typography>
      <Divider />
      <Typography variant="subtitle1" gutterBottom mt={2}>
        Qualifications
      </Typography>
      <Typography variant="body1" mb={2}>
        {provider?.qualificationsExtended}
      </Typography>
      <Divider />
      <Typography variant="subtitle1" gutterBottom textAlign="center" mt={2}>
        Need to make changes to this information?
        <div>
          <Button
            sx={{ m: 2 }}
            variant="outlined"
            color="secondary"
            component="a"
            href={`mailto:${supportEmail}?subject=Please%20update%20my%20profile`}
          >
            Contact Support
          </Button>
        </div>
      </Typography>
    </Paper>
  );
}
