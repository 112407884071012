import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { SyncedCalendar } from "../../services/types";

export const SyncStatus = ({ calendar }: { calendar?: SyncedCalendar[] }) => {
  const theme = useTheme();

  return (
    <Typography display="flex" alignItems="center">
      {calendar?.length ? (
        <>
          <CheckBadgeIcon color={theme.palette.success.main} className="icon" />
          &nbsp; Calendar synced with {calendar[0].profile}
        </>
      ) : (
        <>
          <ExclamationTriangleIcon
            color={theme.palette.error.main}
            className="icon"
          />
          &nbsp; Your calendar is not linked &nbsp;
          <Button
            variant="outlined"
            size="small"
            component={Link}
            to="./availability"
          >
            Link Now
          </Button>
        </>
      )}
    </Typography>
  );
};
