import { Availability } from "./availability";
import { Dashboard } from "./dashboard";
import { MyServices } from "./my-services";

export const routes = [
  {
    title: "My Dashboard",
    index: true,
    path: "",
    element: <Dashboard />,
  },
  {
    title: "My Availability",
    path: "availability",
    element: <Availability />,
  },
  {
    title: "My Services",
    path: "services",
    element: <MyServices />,
  },
];
