import { useLocation, useOutlet } from "react-router-dom";
import { Layout } from "./Layout";
import { AnimatePresence, motion } from "framer-motion";

const pageVariants = {
  initial: {
    x: 600,
  },
  in: {
    x: 0,
  },
  out: {
    x: -600,
  },
};

const pageTransition = {
  type: "tween",
  ease: "easeOut",
  duration: 0.3,
};

export const Onboarding = () => {
  const outlet = useOutlet();
  const { pathname } = useLocation();

  return (
    <Layout>
      <div style={{ position: "relative", width: "100%" }}>
        <AnimatePresence initial={false}>
          <motion.div
            style={{ position: "absolute", width: "100%" }}
            key={pathname}
            transition={pageTransition}
            variants={pageVariants}
            initial="initial"
            exit="out"
            animate="in"
          >
            {outlet}
          </motion.div>
        </AnimatePresence>
      </div>
    </Layout>
  );
};
