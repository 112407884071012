import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";
import { useCalendarSync } from "../services/api";

const useCronofyBase = (autoSync?: boolean) => {
  const [linkingSuccess, setLinkingSuccess] = useState(false);
  const [isLinking, setIsLinking] = useState(false);
  const windowHandle = useRef<Window | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const {
    calendar,
    updateCalendar,
    unlinkCalendar,
    getSyncAuthUrl,
    isUnlinkingCalendar,
  } = useCalendarSync();

  useEffect(() => {
    if (autoSync) {
      const syncCalendar = async () => {
        const primaryCalendar = calendar?.filter((c) => c.isPrimary)[0];
        if (primaryCalendar && primaryCalendar.id) {
          await updateCalendar(primaryCalendar.id);
          enqueueSnackbar("Your calendar has been synchronized!", {
            variant: "success",
          });
          setIsLinking(false);
        }
      };
      if (linkingSuccess) {
        syncCalendar();
      }
    }
  }, [autoSync, calendar, linkingSuccess, enqueueSnackbar, updateCalendar]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (windowHandle.current) {
        // Separately check for closed window to handle completion or manual window close
        if (windowHandle.current.closed) {
          windowHandle.current = null;
          if (!linkingSuccess) {
            setIsLinking(false);
            enqueueSnackbar("Your calendar has not been synchroised", {
              variant: "warning",
            });
          }
        } else if (
          Object.keys(windowHandle.current.window.location).includes("search")
        ) {
          const { location } = windowHandle.current.window;
          if (location.search.includes("status=success")) {
            windowHandle.current.window.close();
            setLinkingSuccess(true);
          }
        }
      }
    }, 500);
    return () => clearInterval(timer);
  }, [linkingSuccess, enqueueSnackbar]);

  const openSyncWindow = async () => {
    setIsLinking(true);
    const authUrl = await getSyncAuthUrl();
    const width = 460;
    const height = 650;
    const left = window.screenLeft + window.outerWidth / 2 - width / 2;
    const top = window.screenLeft + window.outerHeight / 2 - height / 2;
    windowHandle.current = window.open(
      authUrl,
      "Calendar Sync",
      `popup=1,width=${width},height=${height},screenX=${left},screenY=${top}`
    );
  };

  async function unsync() {
    try {
      await unlinkCalendar();
      enqueueSnackbar("Your calendar is no longer being synchronized", {
        variant: "success",
      });
    } catch {
      enqueueSnackbar("There was an error updating your calendar", {
        variant: "error",
      });
    }
  }

  return {
    isLinking,
    linkingSuccess,
    openSyncWindow,
    unsync,
    isUnsyncing: isUnlinkingCalendar,
  };
};

export const useCronofy = () => ({ ...useCronofyBase(false) });

export const useCronofyAutoSync = () => ({ ...useCronofyBase(true) });
