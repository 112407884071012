import {
  Typography,
  Button,
  Stack,
  FormControl,
  MenuItem,
  Select,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { MinusIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { UseFormReturn, useForm } from "react-hook-form";
import { range, zeroPad } from "../../utils";
import { sessionLength } from "../../theme";
import { useCalendarConfig } from "../../services/api";
import { Helmet } from "react-helmet";
import { WorkingHours } from "../../services/types";

type AvailabilityForm = {
  day: string[];
  startTime: string;
  endTime: string;
};

const Hours = ({ form }: { form: UseFormReturn<AvailabilityForm, any> }) => {
  const hours = range(24);

  return (
    <Stack>
      <Helmet>
        <title>My Availablity</title>
      </Helmet>
      <Typography variant="subtitle1">Hours I am available</Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <FormControl fullWidth sx={{ minWidth: 130 }}>
          <Select
            labelId="from-label"
            defaultValue={"09:00:00"}
            {...form.register("startTime")}
          >
            {hours.map((h) => (
              <MenuItem key={h} value={`${zeroPad(h)}:00:00`}>
                From {h}:00
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box justifyItems="center" sx={{ minWidth: 32 }}>
          <MinusIcon style={{ margin: "auto" }} />
        </Box>
        <FormControl fullWidth sx={{ minWidth: 130 }}>
          <Select
            labelId="to-label"
            defaultValue={"17:00:00"}
            {...form.register("endTime")}
          >
            {hours.map((h) => (
              <MenuItem key={h} value={`${zeroPad(h)}:00:00`}>
                To {h}:00
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Stack>
  );
};

const DayCheck = ({
  label,
  value,
  form,
  defaultChecked = false,
}: {
  label: string;
  value: string;
  form: UseFormReturn<AvailabilityForm, any>;
  defaultChecked?: boolean;
}) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Checkbox defaultChecked={defaultChecked} {...form.register("day")} />
      }
      label={label}
      labelPlacement="bottom"
      sx={{ m: 0 }}
    />
  );
};

const Days = ({ form }: { form: UseFormReturn<AvailabilityForm, any> }) => {
  return (
    <Stack>
      <Typography variant="subtitle1">Days I am available</Typography>
      <FormControl component="fieldset">
        <FormGroup aria-label="position" row>
          <DayCheck form={form} label="Sun" value="0" />
          <DayCheck form={form} label="Mon" value="1" defaultChecked />
          <DayCheck form={form} label="Tue" value="2" defaultChecked />
          <DayCheck form={form} label="Wed" value="3" defaultChecked />
          <DayCheck form={form} label="Thu" value="4" defaultChecked />
          <DayCheck form={form} label="Fri" value="5" defaultChecked />
          <DayCheck form={form} label="Sat" value="6" />
        </FormGroup>
      </FormControl>
    </Stack>
  );
};

export const Availability = () => {
  const form = useForm<AvailabilityForm>();
  const navigate = useNavigate();
  const calendarConfig = useCalendarConfig();

  const setHours = async (hours: AvailabilityForm) => {
    const workingHours: WorkingHours[] = hours.day.map((d) => ({
      dayOfWeek: parseInt(d),
      startTime: hours.startTime,
      endTime: hours.endTime,
      bufferTime: "00:15:00",
      sessionLength,
    }));

    // TODO: Let the user set their time zone
    // Get tine browser time zone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    await calendarConfig.setWorkingHours({
      workingHours,
      timeZone,
    });
    navigate("../complete");
  };

  return (
    <Stack
      component="form"
      onSubmit={form.handleSubmit(setHours)}
      spacing={2}
      maxWidth={400}
      m="auto"
      mt={2}
    >
      <Typography component="h1" variant="h5" gutterBottom>
        Set your base availability
      </Typography>
      <Typography component="p" textAlign="center" sx={{ maxWidth: 400 }}>
        Let Ignite clients know when you're typically available to accept
        appointments. Don't worry, you'll be able to customise this later on!
      </Typography>
      <Hours form={form} />
      <Days form={form} />
      <Stack alignItems="center" spacing={2} pt={2}>
        <Button
          component="button"
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          endIcon={<ArrowRightIcon className="icon" />}
        >
          Continue
        </Button>
        <Button component={Link} to="../complete" color="secondary">
          Do this later
        </Button>
      </Stack>
    </Stack>
  );
};
