import { XMarkIcon } from "@heroicons/react/24/outline";
import { Box, IconButton, Popover, PopoverActions } from "@mui/material";
import React from "react";
import {
  ReactNode,
  createContext,
  PropsWithChildren,
  useContext,
  useState,
  useRef,
} from "react";

type popoverContext = {
  show: (anchorEl: Element, content: ReactNode) => void;
  close: () => void;
  reposition: () => void;
};

const PopoverContext = createContext<popoverContext>({
  show: () => {},
  close: () => {},
  reposition: () => {},
});

interface Props {
  anchorEl?: Element | null;
  handleClose: () => void;
}

const PopupBox = React.forwardRef<PopoverActions, Props & PropsWithChildren>(
  ({ children, anchorEl, handleClose }, actions) => {
    const open = Boolean(anchorEl);

    return (
      <Popover
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handleClose}
        open={open}
        anchorEl={anchorEl}
        action={actions}
      >
        <Box
          sx={{
            backgroundColor: (t) => t.palette.primary.main,
            width: "100%",
            height: 5,
          }}
        />
        {children}
      </Popover>
    );
  }
);

export const usePopup = () => {
  return useContext(PopoverContext);
};

export const ClosePopupButton = () => {
  const { close } = usePopup();

  return (
    <IconButton onClick={close}>
      <XMarkIcon className="icon" />
    </IconButton>
  );
};

export const PopupContext = ({ children }: PropsWithChildren) => {
  const [popAnchorEl, setPopAnchorEl] = useState<Element | null>();
  const [popChildren, setPopChildren] = useState<ReactNode | null>();
  const actions = useRef<PopoverActions>(null);

  const show = (anchorEl: Element, content: ReactNode) => {
    setPopAnchorEl(anchorEl);
    setPopChildren(content);
  };

  const close = () => {
    setPopAnchorEl(null);
    setPopChildren(null);
  };

  const reposition = () => {
    actions.current?.updatePosition();
  };

  return (
    <PopoverContext.Provider value={{ show, close, reposition }}>
      <PopupBox
        handleClose={close}
        children={popChildren}
        anchorEl={popAnchorEl}
        ref={actions}
      />
      {children}
    </PopoverContext.Provider>
  );
};
